<template>
  <div>
    <div class="loader" v-if="loading"></div>
    <CRow v-show="!loading">
      <div class="d-flex justify-content-between w100 mb10">
        <div class="d-flex justify-content-start">
          <CInput
            class="mb0 mr-2"
            placeholder="search location"
            v-model="filters"
            @input="onInput()"
          ></CInput>
          <CButton
            color="danger"
            :disabled="selected_properties_id.length == 0"
            @click="showDeleteConfirmationModal = true"
            >Delete Properties</CButton
          >
        </div>
        <div>
          <CButton
            type="submit"
            color="success"
            class="mr-2"
            @click="getCreatePage"
            >Create Property
          </CButton>
          <CButton color="primary" @click="getBulkUploadInstructions()"
            >Bulk Upload</CButton
          >
        </div>
      </div>
      <!-- UPLOADING -->
      <div
        class="alert alert-info mt-2"
        role="alert"
        style="width: 100%"
        v-if="pusher_success && pusher_response.status == 'uploading'"
      >
        <div
          class="spinner-border spinner-border-sm text-info mr-3"
          role="status"
        ></div>
        <span>{{ pusher_response.message }}</span>
      </div>
      <!-- UPLOADED -->
      <div
        class="alert alert-success mt-2"
        role="alert"
        style="width: 100%"
        v-if="showSuccessAlert"
      >
        <CIcon name="cilCheckCircle" class="mr-3" size="lg" />
        <span>{{ pusher_response.message }}</span>
      </div>
      <!-- ERROR -->
      <div
        class="alert alert-danger mt-2"
        role="alert"
        style="width: 100%"
        v-if="showErrorAlert"
      >
        <CIcon name="cilWarning" class="mr-3" size="lg" />

        {{ pusher_response.message }}
      </div>

      <table class="table table-dark">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                v-model="selectAllProperties"
                name=""
                id=""
                @click="selectAll"
              />
            </th>
            <th scope="col">Property Id</th>
            <th scope="col">Title</th>
            <th scope="col">Location</th>
            <!-- <th scope="col">Price</th> -->
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item['id']">
            <td>
              <input
                type="checkbox"
                name=""
                id=""
                :value="item['id']"
                v-model="selected_properties_id"
              />
            </td>
            <td>{{ getPropertyId(index + 1) }}</td>
            <td>{{ item["title"] || "N/A" }}</td>
            <td>{{ item["location"] }}</td>
            <!-- <td>{{ item["price"] }}</td> -->
            <td>
              <CButton
                class="right-create"
                size="sm"
                color="primary"
                @click="onClickEdit(item['id'])"
                >Edit</CButton
              >&nbsp;
              <CButton
                class="right-create"
                size="sm"
                color="danger"
                @click="deleteProperty(item['id'])"
                >Delete</CButton
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end w-100 mb-3">
        <nav aria-label="Page navigation example" class="mr-3">
          <ul class="pagination mb-0">
            <li class="page-item">
              <a
                class="page-link"
                @click="onLoad('prev')"
                aria-label="Previous"
                id="prev"
              >
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: t === current_page }"
              v-for="t in totalPage"
              :key="t"
            >
              <a class="page-link" @click="onPageLoad(t)">{{ t }}</a>
            </li>
            <li class="page-item">
              <a
                class="page-link"
                @click="onLoad('next')"
                aria-label="Next"
                id="next"
              >
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
        <select v-model="selected" @change="onSelect" class="px-3">
          <option>5</option>
          <option :selected="true">10</option>
          <option>50</option>
        </select>
      </div>
    </CRow>

    <!-- DELETE CONFIRMATION -->
    <CModal
      :show.sync="showDeleteConfirmationModal"
      title="Delete Properties"
      color="dark"
    >
      <div>
        <h6>Are you sure you want to delete the selected properties?</h6>
      </div>
      <template #header>
        <h6 class="modal-title">Delete Properties</h6>
        <CButtonClose
          @click="showDeleteConfirmationModal = false"
          class="text-white"
        />
      </template>
      <template #footer>
        <CButton
          @click="showDeleteConfirmationModal = false"
          variant="outline"
          color="primary"
          >Cancel</CButton
        >
        <CButton @click.prevent="bulkDelete" color="danger">Delete</CButton>
      </template>
    </CModal>

    <!-- BULK UPLOAD -->
    <CModal
      :close-on-backdrop="false"
      :show.sync="showBulkUploadModal"
      title="Bulk Upload Properties"
      scrollable
      size="xl"
      color="dark"
      @close="showBulkUploadModal = false"
    >
      <div class="mx-3">
        <!-- SAMPLE FILE DOWNLOAD BUTTON -->
        <a
          :href="download_link"
          class="btn btn-success"
          download="Bulk Upload Sample File"
          role="button"
          >Download Sample csv/xls File</a
        >
        <hr />
        <!-- FILE UPLOAD BUTTON -->
        <div>
          <p>Please upload your csv/xls file :</p>
          <div class="input-group mb-3">
            <div>
              <input
                type="file"
                id="inputGroupFile01"
                @click="
                  (event) => {
                    event.target.value = null;
                  }
                "
                @change="onFileSelected"
                accept=".xlsx, .xls, .csv"
              />
              <label class="custom-file-label" for="inputGroupFile01">{{
                bulkUploadFile ? bulkUploadFile.name : "Choose file"
              }}</label>
            </div>
          </div>
          <p class="small">Max file upload size: 2 MB</p>
          <p
            v-if="bulkUploadFile && bulkUploadFile.size > 2097152"
            class="text-danger"
          >
            The uploaded file exceeds the 2 MB limit. Please upload a file with
            size &#60; 2MB
          </p>
          <CButton
            @click="bulkUpload"
            :disabled="
              bulkUploadFile == null ||
              (bulkUploadFile && bulkUploadFile.size > 2097152)
            "
            color="info"
            >{{ bulkUploadLoader ? "Uploading..." : "Upload" }}</CButton
          >
          <div class="mt-2">
            <p
              v-for="(error, index) in errorMsgs"
              :key="index"
              class="text-danger mb-0"
            >
              {{ error }}
            </p>
          </div>
        </div>
        <hr />
        <!-- INSTRUCTIONS -->
        <div>
          <h5>INSTRUCTIONS</h5>
          <p>
            Please follow the given instructions carefully before uploading the
            file.
          </p>
          <div>
            <table class="table">
              <thead>
                <tr class="table-dark">
                  <th scope="col">#</th>
                  <th scope="col">Column Name</th>
                  <th scope="col">Required</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody v-if="!bulkUploadInstructions.length">
                <tr>
                  Loading...
                </tr>
              </tbody>
              <tbody v-else>
                <tr
                  v-for="(instruction, index) in bulkUploadInstructions"
                  :key="index"
                >
                  <!-- <th scope="row">{{index+1}}</th> -->
                  <td>{{ index + 1 }}</td>
                  <td>{{ instruction.column_name }}</td>
                  <td>{{ instruction.required }}</td>
                  <td>{{ instruction.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <template #header>
        <h6 class="modal-title">Bulk Upload Properties</h6>
        <CButtonClose @click="showBulkUploadModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton
          @click="showBulkUploadModal = false"
          variant="outline"
          color="primary"
          >Cancel</CButton
        >
        <!-- <CButton @click.prevent="bulkDelete" color="danger">Delete</CButton> -->
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  getProperties,
  deleteProperty,
  bulkDeleteProperties,
  bulkUploadProperties,
  bulkUploadInstructions,
} from "@/api/properties";
import { getToken } from "@/utils/auth";

export default {
  name: "Properties",
  data() {
    return {
      items: [],
      fields: [{ key: "location" }, { key: "price" }, { key: "actions" }],
      modalShow: false,
      property_id: 0,
      perPage: 10,
      prev: "",
      next: "",
      last: "",
      totalData: "",
      totalPage: "",
      current_page: 1,
      value: "",
      selected: 10,
      filters: "",
      selected_properties_id: [],
      selectAllProperties: false,
      showDeleteConfirmationModal: false,
      showBulkUploadModal: false,
      bulkUploadFile: null,
      loading: false,
      errorMsgs: [],
      bulkUploadLoader: false,
      download_link: process.env.VUE_APP_BASE_API + "/Samplefile.xlsx",
      bulkUploadInstructions: [],
      showSuccessAlert: false,
      showErrorAlert: false,
    };
  },
  created() {
    // window.setTimeout(function () {
    //      $("#success-alert").alert('close');
    //   }, 2000);
    getProperties(1, this.perPage, this.filters)
      .then((res) => {
        this.items = (res.data && res.data.data.data) || [];
        this.perPage = res.data.data.per_page;
        this.prev = res.data.data.prev_page_url;
        this.next = res.data.data.next_page_url;
        if (this.prev === null) {
          document.getElementById("prev").classList.add("disable_a_href");
        }
        this.last = res.data.data.last_page_url;
        if (this.next === null) {
          document.getElementById("next").classList.add("disable_a_href");
        }
        this.totalData = res.data.data.total;
        this.totalPage = Math.ceil(this.totalData / this.perPage);
      })
      .catch((error) => {
        // reject(error)
        console.log(error, "error");
      });
    console.log("dergvere", this.pusher_response);
  },
  paginationProps: {
    align: "center",
    doubleArrows: false,
    previousButtonHtml: "prev",
    nextButtonHtml: "next",
  },
  computed: {
    // pusher_response() {
    //   return this.$store.getters.pusher_response;
    // },
    ...mapGetters(["pusher_success", "pusher_response"]),
  },
  watch: {
    selected_properties_id(new_val) {
      if (new_val.length == this.items.length) {
        this.selectAllProperties = true;
      } else {
        this.selectAllProperties = false;
      }
    },
    pusher_response(val) {
      if (
        this.pusher_response &&
        this.pusher_response.success == true &&
        this.pusher_response.status == "uploaded"
      ) {
        this.onPageLoad(this.current_page);
        this.showSuccessAlert = true;
        setTimeout(() => {
          this.showSuccessAlert = false;
          this.RESET_PUSHER_RESPONSE();
        }, 5000);
      }
      if (this.pusher_response && this.pusher_response.success == false) {
        this.onPageLoad(this.current_page);
        this.showErrorAlert = true;
        setTimeout(() => {
          this.showErrorAlert = false;
          this.RESET_PUSHER_RESPONSE();
        }, 5000);
      }
    },
  },

  methods: {
    ...mapMutations("pusher", ["RESET_PUSHER_RESPONSE"]),
    getPropertyId(data) {
      if (this) return (this.current_page - 1) * parseInt(this.perPage) + data;
      return null;
    },
    onInput() {
      getProperties(1, this.perPage, this.filters)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;

          if (this.prev === null) {
            document.getElementById("prev").classList.add("disable_a_href");
          }

          this.last = res.data.data.last_page_url;
          if (this.next === null) {
            document.getElementById("next").classList.add("disable_a_href");
          }
          this.next = res.data.data.next_page_url;
          this.totalData = res.data.data.total;
          this.totalPage = Math.ceil(this.totalData / this.perPage);
        })
        .catch((error) => {
          // reject(error)
          console.log(error, "error");
        });
    },
    onSelect(event) {
      let value = event.target.value;
      getProperties(1, value, this.filters)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          this.isLoading = false;
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;
          if (this.prev === null) {
            document.getElementById("prev").classList.add("disable_a_href");
          }
          this.last = res.data.data.last_page_url;
          if (this.next === null) {
            document.getElementById("next").classList.add("disable_a_href");
          }
          this.next = res.data.data.next_page_url;
          this.totalData = res.data.data.total;
          this.totalPage = Math.ceil(this.totalData / this.perPage);
        })
        .catch((error) => {
          // reject(error)
          console.log(error, "error");
        });
    },
    onLoad(value) {
      if (value === "prev") {
        if (this.current_page != 1) this.current_page = this.current_page - 1;
      } else if (this.current_page < this.totalPage) {
        this.current_page = this.current_page + 1;
      }
      this.onPageLoad(this.current_page);
    },
    onPageLoad(page) {
      getProperties(page, this.perPage, this.filters)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;
          this.last = res.data.data.next_page_url;
          
          if (this.prev !== null) {
            document.getElementById("prev").classList.remove("disable_a_href");
          } else {
            document.getElementById("prev").classList.add("disable_a_href");
          }
          if (this.next !== null) {
            document.getElementById("next").classList.remove("disable_a_href");
          } else {
            document.getElementById("next").classList.add("disable_a_href");
          }
          
        })
        .catch((error) => {
          console.log(error, "error");
        });
      this.current_page = page;
    },
    onClickEdit(id) {
      this.$router.push("/admin/properties/" + id);
      this.modalShow = true;
      this.property_id = id;
    },
    getCreatePage() {
      this.$router.push("/admin/properties/add");
    },
    onHideModal() {
      this.modalShow = !this.modalShow;
      this.property_id = 0;
    },
    savePropertyData(data, id = null) {
      if (id) {
        var foundIndex = this.items.findIndex((x) => x.id == id);
        this.items[foundIndex] = data;
      } else {
        this.perPage = data.per_page;
        this.totalData = data.total;
        this.totalPage = Math.ceil(this.totalData / this.perPage);
        this.items = data.data;
      }
    },
    deleteProperty(id) {
      if (confirm("Do you really want to delete this  property?")) {
        deleteProperty(id, getToken())
          .then((res) => {
            this.items = (res.data && res.data.data.data) || [];
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });
            // this.perPage = res.data.data.per_page;
            this.totalData = res.data.data.total;
            this.totalPage = Math.ceil(this.totalData / this.perPage);
          })
          .catch((error) => {
            if (error.response.status == 501) {
              this.$notify({
                group: "notify",
                type: "error",
                text: error.response.data.data,
              });
            }
            console.log(error);
          });
      }
    },
    selectAll(event) {
      if (event.target.checked) {
        this.selected_properties_id = this.items.map((item) => item.id);
      } else {
        this.selected_properties_id = [];
      }
    },
    onFileSelected(event) {
      this.errorMsgs = [];
      console.log("file selected : ", event.target.files[0]);
      this.bulkUploadFile = event.target.files[0];
    },
    getBulkUploadInstructions() {
      this.showBulkUploadModal = true;
      bulkUploadInstructions()
        .then((res) => {
          console.log("Instructions : ", res.data);
          this.bulkUploadInstructions = res.data;
        })
        .catch((error) => {
          this.$notify({
            group: "notify",
            type: "error",
            text: error.response.data.data,
          });
        });
    },
    bulkUpload() {
      if (this.bulkUploadFile.size < 2097152) {
        this.bulkUploadLoader = true;
        var data = new FormData();
        data.append("file", this.bulkUploadFile);
        bulkUploadProperties(data)
          .then((res) => {
            this.onPageLoad(this.current_page);
            // this.loading = false;
            this.bulkUploadLoader = false;
            this.showBulkUploadModal = false;
            this.bulkUploadFile = null;
            this.errorMsgs = [];
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });

            // this.selected_properties_id = [];
          })
          .catch((error) => {
            this.bulkUploadLoader = false;
            this.bulkUploadFile = null;
            if (error.response.data.data) {
              for (const key in error.response.data.data) {
                this.errorMsgs.push(error.response.data.data[key][0]);
              }
              console.log("errors : ", this.errorMsgs);
            }
            if (error.response.data.errors) {
              for (const key in error.response.data.errors) {
                this.errorMsgs.push(error.response.data.errors[key][0]);
              }
              console.log("errors : ", this.errorMsgs);
            }
            // this.$notify({
            //   group: "notify",
            //   type: "error",
            //   text: error.response.data.data,
            // });
          });
      }
    },
    bulkDelete() {
      this.loading = true;
      this.showDeleteConfirmationModal = false;
      console.log("aee");
      bulkDeleteProperties({ property_ids: this.selected_properties_id })
        .then((res) => {
          this.onPageLoad(this.current_page);
          this.loading = false;
          this.$notify({
            group: "notify",
            type: "success",
            text: res.data.message,
          });
          this.selected_properties_id = [];
        })
        .catch((error) => {
          this.loading = false;
          this.$notify({
            group: "notify",
            type: "error",
            text: error.response.data.data,
          });
        });
    },
  },
};
</script>

<style scoped>
.forDiv {
  display: flex;
  justify-content: space-between;
}
.disable_a_href {
  pointer-events: none;
}
.active {
  background-color: blue;
}
li {
  cursor: pointer;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.custom-file-label {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 80px;
  border: 1px solid black;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
